<template>
  <div class="refusal-loan">
    <v-container class="pa-0 mb-0 mb-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <v-col cols="12">
              <h3 class="heading-h3">My loan</h3>
            </v-col>

            <!-- Main -->
            <InnerCard color="warning50">
              <h5 class="heading-h5 warning500--text mb-3">
                {{ message }}
              </h5>
            </InnerCard>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InnerCard from "@/components/shared/cards/InnerCard";

export default {
  components: { InnerCard },

  name: "LimeLoansRefusal",

  data() {
    return {};
  },

  computed: {
    ...mapState({
      auth: "auth",
    }),

    message() {
      return "Unfortunately, your application was declined. If in future your situation changes you are welcome to re-apply in 30 days. Should you wish to know the reason for the decline, please contact Support or “Chat” with us on Live Agent.";
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
