<template>
  <div>
    <Unsubscribe />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Unsubscribe from "@/components/dashboard/pages/Unsubscribe/Unsubscribe";

export default {
  name: "LimeLoansRefusalPage",

  components: { Unsubscribe },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
