<template>
  <div>
    <Refusal />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Refusal from "@/components/dashboard/pages/refusal/Refusal";

export default {
  name: "LimeLoansRefusalPage",

  components: { Refusal },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    if (!this.auth.user.negative_classification && this.auth.user.can_apply) {
      this.$router.push({ name: "DashboardMyLoanPage" });
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
