<template>
  <div class="container">
    <div class="card">
      <h1>Unsubscribe</h1>
      <p>
        We're sorry to see you go! You will not receive any more emails from
        Lime.
      </p>
      <textarea
        v-model="email"
        placeholder="Please provide your email address"
        required
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        :class="{ 'invalid-email': !isEmailValid }"
        @input="validateEmail"
      ></textarea>
      <!-- Checkboxes for unsubscribe reasons -->
      <div class="checkboxes">
        <label class="checkbox-label">
          <input type="checkbox" v-model="reasons" value="no_interest" />
          <span class="checkmark"></span> I am no longer interested in a loan.
        </label>
        <br />
        <label class="checkbox-label">
          <input
            type="checkbox"
            v-model="reasons"
            value="assisted_by_another"
          />
          <span class="checkmark"></span> I was assisted by another credit
          provider.
        </label>
        <br />
        <label class="checkbox-label">
          <input
            type="checkbox"
            v-model="reasons"
            value="speak_to_consultant"
          />
          <span class="checkmark"></span> I would rather speak to a consultant.
        </label>
        <br />
        <label class="checkbox-label">
          <input type="checkbox" v-model="reasons" value="complete_later" />
          <span class="checkmark"></span> I will complete the application at a
          later stage.
        </label>
        <br />
        <label class="checkbox-label">
          <input type="checkbox" v-model="reasons" value="other" />
          <span class="checkmark"></span> Other
        </label>
        <!-- Added text box for "Other" -->
        <textarea
          v-if="reasons.includes('other')"
          v-model="otherReason"
          placeholder="Please specify..."
        ></textarea>
      </div>

      <div class="hidden-xs-only btn">
        <v-btn
          :disabled="!isEmailValid"
          class="b-button primary text-capitalize px-8"
          height="48"
          elevation="0"
          @click="unsubscribe"
          >Unsubscribe</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UnsubscribePage",
  data() {
    return {
      reasons: [],
      otherReason: "",
      email: "",
      isEmailValid: false,
    };
  },
  methods: {
    validateEmail() {
      // Check if the email input matches the specified pattern
      this.isEmailValid = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(
        this.email
      );
    },
    unsubscribeUrl(data) {
      const baseURL = "https://testlimeloansapigateway.softidoc.co.za/";
      return axios.post(`${baseURL}unsubscribe/unsubscribemail`, data);
    },

    async postData() {
      const data = {
        email: this.email,
        reason: this.reasons,
        other: this.otherReason,
      };

      try {
        const response = await this.unsubscribeUrl(data);
        console.log("Option created successfully:", response.data);
      } catch (error) {
        console.error("Error creating option:", error);
      }
    },

    unsubscribe() {
      console.log("Unsubscribe Reasons:", this.reasons);
      console.log("Other Reason:", this.otherReason);
      console.log("Other Reason:", this.email);
      this.postData();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card {
  background-color: white;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.checkboxes {
  text-align: left;
}

.checkbox-label {
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  display: block;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.checkbox-label input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #5cb85c;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

p {
  margin-top: 15px;
  margin-bottom: 55px;
  font-weight: 500;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

.btn {
  margin-top: 35px;
}
</style>
